<template>
  <div v-if="this.customer.electronic_invoice">
    <div>
      <!-- filter invoices -->
      <div class="container-alb filter-mobile">
        <table
          cellpadding="10"
          cellspacing="0"
          width="95%"
          class="hover-table"
          align="center"
        >
          <tr>
            <td>
              <img src="@/assets/images/filter.svg" />
              <span class="mobile-show margin-left-5">{{
                $t("invoicesFilterTitle")
              }}</span>
            </td>
            <td>
              {{ $t("invoicesFilterProduct") }}
              <select class="filtrare" @change="productSelected">
                <option
                  v-for="(entry, index) in customer.products"
                  :key="index"
                  :value="entry.brand"
                  :selected="isSelectedProduct(entry.brand)"
                >
                {{ entry.name }}
                  <!-- {{
                    getProductNameMethod(
                      entry.card === 0
                        ? entry.paper
                        
                        : entry.card
                    )
                  }} -->
                </option>
              </select>
            </td>
            <td>
              {{ $t("invoicesFilterStatus") }}
              <select class="filtrare" @change="invoiceStatusSelected">
                <option value="A" selected>{{ $t("selectLabel") }}</option>
                <option
                  v-for="(entry, index) in invoiceStatuses"
                  :key="index"
                  :value="entry.value"
                  :selected="isSelectedInvoiceStatus(entry.value)"
                >
                  {{ $t("invoiceStatus_" + entry.value) }}
                </option>
              </select>
            </td>
            <td>
              {{ $t("invoicesFilterPeriod") }}
              <date-range
                class="invoices-filter"
                @close="periodFilterChanged"
                :time="this.filters.period"
              />
            </td>
          </tr>
        </table>
      </div>

      <!-- pending invoices -->
      <div class="container-alb">
        <div class="table-header-wrapper">
          <div class="left">
            <h3 class="table-title">{{ $t("pendingInvoicesTitle") }}</h3>
          </div>
        </div>
        <br />

        <InvoiceTableDesktop
          :company="this.customer"
          :companyInvoices="this.customerPendingInvoices"
          :pending="true"
          @change="sortBy"
        />

        <div
          class="error-wrapper border-box"
          v-if="
            this.customerPendingInvoices &&
              this.customerPendingInvoices.length === 0
          "
        >
          <p>{{ $t("noInvoicesMessage") }}</p>
        </div>
      </div>

      <!-- invoice history -->
      <div class="container-alb">
        <div class="table-header-wrapper">
          <div class="left">
            <h3 class="table-title">{{ $t("lastInvoicesTitle") }}</h3>
          </div>
        </div>
        <br />
        <InvoiceTableDesktop
          :company="this.customer"
          :companyInvoices="this.customerInvoices"
          :pending="false"
          @change="sortBy"
        />

        <div class="pagination-wrapper border-box">
          <b-pagination
            v-if="this.customerInvoices"
            v-model="currentPage"
            :total-rows="this.invoicesCount"
            :per-page="perPage"
            size="sm"
          ></b-pagination>
        </div>

        <div
          class="error-wrapper border-box"
          v-if="this.customerInvoices && this.customerInvoices.length === 0"
        >
          <p>{{ $t("noInvoicesMessage") }}</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container-alb">
      <div class="table-header-wrapper">
        <NoElectronicInvoiceTemplate />
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import utils from "@/plugins/utils.js";

import DateRange from "@/components/DateRange.vue";
import httpServiceUser from "@/http/http-user.js";
import { BPagination } from "bootstrap-vue";
import Cookies from "js-cookie";
import moment from "moment";
import NoElectronicInvoiceTemplate from "@/assets/components/NoElectronicInvoiceTemplate.vue";

export default {
  props: {
    customer: Object,
    msg: String
  },
  components: {
    NoElectronicInvoiceTemplate,
    InvoiceTableDesktop,
    DateRange,
    "b-pagination": BPagination
  },
  data() {
    return {
      invoiceError: null,
      customerPendingInvoices: null,
      customerInvoices: null,
      tempInvoices: null,
      filters: {
        product: "dejun",
        invoiceStatus: "A",
        period: null
      },
      invoiceStatuses: [
        { value: "P", description: "Paid" },
        { value: "U", description: "Unpaid" },
        { value: "O", description: "Overdue" }
      ],
      invoicesCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc"
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getCustomerInvoices("pending");
      this.getCustomerInvoices();
    },
    invoiceStatusSelected(event) {
      this.filters.invoiceStatus = event.target.value;
      this.getCustomerInvoices();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getCustomerInvoices();
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    isSelectedInvoiceStatus() {
      return status === this.filters.invoiceStatus;
    },
    sortBy(sortField, sortDirection, status) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getCustomerInvoices(status);
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    getCustomerInvoices(status) {
      this.customerInvoices = null;
      if (status === "pending") {
        this.customerPendingInvoices = null;
      }
      var invoicesData =
        "?type=" + "customer" + "&brand=" + this.filters.product;
      if (status !== "pending") {
        invoicesData +=
          "&start_date=" +
          moment(this.filters.period[0]).format("YYYYMMDD") +
          "&end_date=" +
          moment(this.filters.period[1]).format("YYYYMMDD");
      }
      if (status === "pending") {
        invoicesData += "&paid=U";
      } else {
        if (this.filters.invoiceStatus !== "A") {
          invoicesData += "&paid=" + this.filters.invoiceStatus;
        } else {
          invoicesData += "&paid=L,R,P";
        }
      }
      invoicesData +=
        "&per_page=" +
        this.perPage +
        "&page=" +
        this.currentPage +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        invoicesData += "&desc=" + this.sortField;
      } else {
        invoicesData += "&asc=" + this.sortField;
      }
      httpServiceUser.userService
        .getCompanyInvoices(
          this.customer.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .catch(error => {
          this.invoiceError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
        })
        .then(response => {
          if (response.status == 200) {
            if (status === "pending") {
              this.customerPendingInvoices = response.data;
            } else {
              this.customerInvoices = response.data;
              this.invoicesCount = response.headers["x-up-count"]; //["Content-Length"];
            }
          } else if (response.status == 400 || response.status == 403) {
            this.invoiceError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.invoiceError = { description: response.description };
          }
        });
    }
  },
  mounted() {
    this.filters.period = [moment().subtract(3, "month"), moment()];
    let productURL = utils.getUrlParam("product");
    if (productURL) {
      this.filters.product = utils.getProductById(
        parseInt(productURL),
        this.customer.products
      ).brand;
    } else {
      this.filters.product = this.customer.products[0].brand;
    }
    this.getCustomerInvoices("pending");
    this.filters.invoiceStatus = "A";
    this.customerInvoices = this.getCustomerInvoices();
  },

  watch: {
    currentPage: function() {
      this.getCustomerInvoices();
    }
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}
</style>
